import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout type={'home'} title={frontmatter.title} description={frontmatter.description}>
      <div className="mw7-m mw8-l center ph3 mb4">
        <div className="cf">
          <div className="fl w-two-thirds-l">
            <h2 className="normal f2">{frontmatter.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: html }}></div>
          </div>
        </div>
      </div>

      <div className="pv4 bt b--light-gray bg-yellow">
        <div className="mw7-m mw8-l center pa3">
          <p className="mv0">
            Send your applications to{' '}
            <a
              className="link near-black underline underline-hover"
              href={`mailto:${frontmatter.mail}`}
            >
              {frontmatter.mail}
            </a>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($pagePath: String!) {
    markdownRemark(frontmatter: { path: { eq: $pagePath } }) {
      html
      frontmatter {
        path
        title
        mail
      }
    }
  }
`
